<template>
    <div class="bg-gray-200">
        <div class="grid bg-gray-100 lg:grid-cols-2 2xl:grid-cols-5">
            <div class="px-8 py-12 max-w-md mx-auto sm:max-w-xl lg:px-12 lg:py-24 lg:max-w-full xl:mr-0 2xl:col-span-2">
                <div class="xl:max-w-xl">
                    <img class="h-10" src="@/assets/img/logo-brand.svg" alt="Workcation">
                    <img class="mt-6 rounded-lg shadow-xl sm:mt-8 sm:h-64 sm:w-full sm:object-cover object-center lg:hidden" src="@/assets/img/beach-work.jpg" alt="Woman workcationing on the beach">
                    <h1 class="mt-6 text-2xl font-headline tracking-tight font-semibold text-gray-900 sm:mt-8 sm:text-4xl lg:text-3xl xl:text-4xl">You can work from anywhere. 
                        <br class="inline" />
                        <span class="text-brand">Take advantage of it.</span></h1>
                    <p class="mt-2 text-gray-600 sm:mt-4 sm:text-xl">Workcation helps you find work-friendly rentals in beautiful locations so you can enjoy some nice weather even when you're not on vacation.</p>
                    <div class="mt-4 space-x-1 sm:mt-4">
                        <a class="btn btn-primary shadow-lg hover:-translate-y-0.5 transform transition" href="#">Book your escape</a>
                        <!--<a class="btn btn-secondary" href="#">Learn More</a>-->
                    </div>
                </div>
            </div>
            <div class="hidden relative lg:block 2xl:col-span-3">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="@/assets/img/beach-work.jpg" alt="Woman workationing on the beach">
            </div>
        </div>

        <div class="max-w-md sm:max-w-xl lg:max-w-6xl mx-auto px-8 lg:px-12 py-8">
            <h2 class="text-xl text-gray-900">Popular destinations</h2>
            <p class="mt-2 text-gray-600">A selection of great work-friendly cities with lots to see and explore.</p>
            <div class="mt-6 grid gap-6 lg:grid-cols-2 xl:grid-cols-3">
                <DestinationCard v-for="(destination, index) in destinations" :key="index" :destination="destination" />
            </div>
        </div>
    </div>
</template>

<script>

import DestinationCard from '@/components/DestinationCard.vue'
import sourceData from '@/db.json'
export default {
  components: {
    DestinationCard
  },
  data () {
    return {
        destinations: sourceData.destinations
    }
  }
}
</script>