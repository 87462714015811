<template>
  <div>
    <page-home />
  </div>
</template>

<script>
import PageHome from '@/pages/PageHome.vue'
export default {
  name: 'App',
  components: {
    PageHome
  }
}
</script>
